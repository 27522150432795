import { Duration, formatISODuration } from "date-fns";
import { range } from "~/lib/utils/utils";
import { useTranslation } from "react-i18next";
import { Label } from "~/lib/ui";
import { Select } from "~/lib/ui/form-elements";
import { capitalize } from "~/lib/utils/string";

export function DurationSelector({
  ...props
}: {
  value?: Duration;
  onChange?: (duration: Duration, key?: string) => void;
  label: string;
  required?: boolean;
  onChangeString?: (duration: string, key?: string) => void;
  minuteOptions?: Array<number>;
  hoursOptions?: Array<number>;
}) {
  const { t } = useTranslation();
  function handleChange(key: "hours" | "minutes", value: string) {
    const dur = { ...props.value };
    dur[key] = parseInt(value, 10);
    props.onChange?.(dur, key);
    props.onChangeString?.(formatISODuration(dur), key);
  }

  const { hours, minutes } = props.value ?? {};
  const minuteOptions = props.minuteOptions ?? range(60).map((i) => i);
  const hoursOptions = props.hoursOptions ?? range(24).map((i) => i);

  const formatToTwoDigits = function (num: number): string {
    return num.toString().padStart(2, "0");
  };

  return (
    <>
      {props.label && <Label required={props.required}>{props.label}</Label>}
      <div className="flex  flex-row flex-wrap gap-4">
        <div className="flex flex-col ">
          <Label>{t("common:hour", { count: 2 })}</Label>
          <Select onChange={(e) => handleChange("hours", e.currentTarget.value)} value={hours}>
            {hoursOptions.map((hour) => (
              <option value={hour} key={hour}>
                {formatToTwoDigits(hour)}
              </option>
            ))}
          </Select>
        </div>
        <div className="flex flex-col">
          <Label>{capitalize(t("common:minute", { count: 2 }))}</Label>
          <Select onChange={(e) => handleChange("minutes", e.currentTarget.value)} value={minutes}>
            {minuteOptions.map((minute) => (
              <option value={minute} key={minute}>
                {formatToTwoDigits(minute)}
              </option>
            ))}
          </Select>
        </div>
      </div>
    </>
  );
}
