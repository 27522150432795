import { FormEvent, forwardRef, HTMLProps, KeyboardEvent, Ref, useId } from "react";
import { Input } from "~/lib/ui/form-elements/input";
import { getCurrentLocale } from "~/lib/i18n/i18n";
import { Label } from "~/lib/ui";

type InputPropWithoutOnChange = Omit<HTMLProps<HTMLInputElement>, "onChange">;
type InputProps = InputPropWithoutOnChange & {
  maximumDecimals?: number;
  onChange?: (value: number) => void; // For future reference, create an `onChangeValue` instead
};

const functionKeys = ["F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9", "F10", "F11", "F12"];
const allowedKeys = [
  ...functionKeys,
  "Backspace",
  "Enter",
  "Delete",
  "Tab",
  "ArrowRight",
  "ArrowLeft",
];

/**
 * A text input that only accepts numbers and formats them according to the maxDecimals prop.
 * Derives directly from <Input />
 * onKeyDown is reserved for this component. Do not overwrite it.
 */
export const NumberFormatInput = forwardRef(function NumberFormatInputInner(
  props: InputProps,
  ref: Ref<HTMLInputElement>
) {
  const { className, id, label, required, maximumDecimals, onChange, ...restProps } = props;
  const defaultDecimals = 2;
  const inputId = useId();

  const handleInput = (e: KeyboardEvent<HTMLInputElement>) => {
    const originalValue = e.currentTarget.value;
    const newValue =
      originalValue.substring(0, e.currentTarget.selectionStart!) +
      e.key +
      originalValue.substring(e.currentTarget.selectionEnd!);

    const characterPressed = e.key;

    // If characterPressed is null, it's a delete or backspace and return/enter is not registered at all, so we don't care
    if (allowedKeys.includes(characterPressed)) {
      return;
    }

    // Check against allowed characters 0-9, dot and comma
    const allowedCharacters = /[0-9\.\,]/;
    const isAllowedCharacter = allowedCharacters.test(characterPressed);

    if (!isAllowedCharacter) {
      e.preventDefault();
      return;
    }

    // Check that we don't have double dot or comma or a combination of both
    const commaCount = (newValue.match(/\,/g) || []).length;
    const dotCount = (newValue.match(/\./g) || []).length;
    const tooManySeparators = commaCount + dotCount > 1;

    if (tooManySeparators) {
      e.preventDefault();
      return;
    }

    // Check that we don't have more than 2 decimals
    const decimals = newValue.split(/\.|\,/)[1];
    if (decimals) {
      const decimalCount = decimals.length;
      if (decimalCount > (maximumDecimals ?? defaultDecimals)) {
        e.preventDefault();
        return;
      }
    }
  };

  const handleOnChange = (e: FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.replace(",", ".");
    if (isNaN(Number(value))) {
      return;
    }

    const num = Number(value);

    onChange?.(num);
  };

  return (
    <>
      {label && (
        <Label required={required} htmlFor={id || inputId}>
          {label}
        </Label>
      )}
      <Input
        {...restProps}
        id={id || inputId}
        onChange={handleOnChange}
        value={restProps.value ? restProps.value : undefined}
        defaultValue={
          restProps.defaultValue
            ? new Intl.NumberFormat(getCurrentLocale(), {
                useGrouping: false,
                minimumFractionDigits: maximumDecimals ?? defaultDecimals,
                maximumFractionDigits: maximumDecimals ?? defaultDecimals,
              }).format(Number(restProps.defaultValue ?? 0))
            : undefined
        }
        className={className}
        ref={ref}
        onKeyDown={handleInput}
      />
    </>
  );
});
