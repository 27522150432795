type TimeStringSeparator = "dot" | "colon";

type TimeStringOptions = {
  hours?: boolean;
  minutes?: boolean;
  seconds?: boolean;
};

export const dateToTime = (
  date?: Date | null,
  separator: TimeStringSeparator = "colon",
  options: TimeStringOptions = {}
): string | null => {
  if (!date) return null;

  const defaultTimeStringOptions: TimeStringOptions = {
    hours: true,
    minutes: true,
    seconds: false,
  };

  const timeStringOptions: TimeStringOptions = Object.assign(defaultTimeStringOptions, options);

  const locale = separator === "colon" ? "en-GB" : "da-DK";
  return date?.toLocaleTimeString(locale, {
    hour: timeStringOptions.hours ? "numeric" : undefined,
    minute: timeStringOptions.minutes ? "numeric" : undefined,
    second: timeStringOptions.seconds ? "numeric" : undefined,
  });
};
/**
 * Converts a number to a time string with proper padding
 * Returns empty string on null or undefined
 */
export const numberToTime = (num: number | null | undefined): string => {
  if (num === null || num === undefined) return "";
  return num < 10 ? `0${num}` : num.toString();
};
/**
 * Filters the hours of the day based on the given condition
 * @param {number} hour - The reference hour (0 to 23) used for filtering.
 * @param {boolean} isLarger - Determine filtering condition
 *  - If `true` returns hours greater or equal than `hour`.
 *  - If `false` returns hours lesser or equal than `hour`
 */
export const filterHours = (hour: number, isLarger: boolean) =>
  [...Array(24).keys()].filter((h) => (isLarger ? h >= hour : h <= hour));
