/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
};

export type City = Entity & {
  __typename?: 'City';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type Contact = Entity & {
  __typename?: 'Contact';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<City>;
  cityId: Scalars['ID']['output'];
  cityName?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['ID']['output']>;
  contactPersons?: Maybe<Array<ContactPerson>>;
  country?: Maybe<Country>;
  countryId: Scalars['ID']['output'];
  created: Scalars['String']['output'];
  createdById: Scalars['ID']['output'];
  cvr?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  ean?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  erpDaysOfCredit?: Maybe<Scalars['Int']['output']>;
  erpId?: Maybe<Scalars['String']['output']>;
  erpPaymentTermId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  modified: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  phoneCountrycode?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  edges: Array<ContactEdge>;
  pageInfo: PageInfo;
};

export type ContactEdge = PaginationEdge & {
  __typename?: 'ContactEdge';
  cursor: Scalars['String']['output'];
  node: Contact;
};

export type ContactListParams = {
  direction?: InputMaybe<Pagination_Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Project_List_Sort_Fields>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ContactPerson = Entity & {
  __typename?: 'ContactPerson';
  contactId: Scalars['ID']['output'];
  created: Scalars['String']['output'];
  createdById: Scalars['ID']['output'];
  deleted?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Country = Entity & {
  __typename?: 'Country';
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Entity = {
  id: Scalars['ID']['output'];
};

export enum Form_List_Sort_Fields {
  FormDate = 'formDate',
  Id = 'id'
}

export type Form = Entity & {
  __typename?: 'Form';
  created: Scalars['String']['output'];
  deleted?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['String']['output']>;
  project?: Maybe<Project>;
  projectId: Scalars['ID']['output'];
};

export type FormConnection = {
  __typename?: 'FormConnection';
  edges: Array<FormEdge>;
  pageInfo: PageInfo;
};

export type FormEdge = PaginationEdge & {
  __typename?: 'FormEdge';
  cursor: Scalars['String']['output'];
  node: Form;
};

export type FormsListParams = {
  direction?: InputMaybe<Pagination_Direction>;
  formDate?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Form_List_Sort_Fields>;
};

export type Invoice = Entity & {
  __typename?: 'Invoice';
  combineProductLines: Scalars['Boolean']['output'];
  combineWorkingTimeLines: Scalars['Boolean']['output'];
  companyId: Scalars['ID']['output'];
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['ID']['output']>;
  contributionMargin: Scalars['Float']['output'];
  contributionMarginPerHour: Scalars['Float']['output'];
  contributionRate: Scalars['Float']['output'];
  costPriceTotal: Scalars['Float']['output'];
  created: Scalars['String']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['ID']['output'];
  currencyId?: Maybe<Scalars['ID']['output']>;
  dateFrom?: Maybe<Scalars['Date']['output']>;
  dateTo?: Maybe<Scalars['Date']['output']>;
  daysOfCredit?: Maybe<Scalars['Int']['output']>;
  deleted?: Maybe<Scalars['String']['output']>;
  discountPercent: Scalars['Float']['output'];
  erpId?: Maybe<Scalars['String']['output']>;
  erpPaymentTermId?: Maybe<Scalars['String']['output']>;
  euCustomer: Scalars['Boolean']['output'];
  grossPayment: Scalars['Float']['output'];
  groupByForms: Scalars['Boolean']['output'];
  hasProjectPdfAttached: Scalars['Boolean']['output'];
  hourUsageAmount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  includeInvoicedForms: Scalars['Boolean']['output'];
  includingVat?: Maybe<Scalars['Boolean']['output']>;
  integrationId?: Maybe<Scalars['ID']['output']>;
  integrationImageUrl?: Maybe<Scalars['String']['output']>;
  invoiceNumber?: Maybe<Scalars['Int']['output']>;
  isBooked: Scalars['Boolean']['output'];
  isDraft?: Maybe<Scalars['Boolean']['output']>;
  isFinalInvoice: Scalars['Boolean']['output'];
  isLocked: Scalars['Boolean']['output'];
  isManuallyInvoiced: Scalars['Boolean']['output'];
  isOffer: Scalars['Boolean']['output'];
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  issuedDate?: Maybe<Scalars['Date']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  modified: Scalars['String']['output'];
  netPayment: Scalars['Float']['output'];
  offerNumber?: Maybe<Scalars['Int']['output']>;
  paidDate?: Maybe<Scalars['String']['output']>;
  paymentDueDate?: Maybe<Scalars['Date']['output']>;
  paymentTermId?: Maybe<Scalars['ID']['output']>;
  pdfUrl: Scalars['String']['output'];
  prettyDates?: Maybe<Scalars['String']['output']>;
  productUsageAmount?: Maybe<Scalars['Float']['output']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['ID']['output']>;
  projectOverviewAttached: Scalars['Boolean']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  salesPriceTotal: Scalars['Float']['output'];
  senderId?: Maybe<Scalars['ID']['output']>;
  showEmployeeName: Scalars['Boolean']['output'];
  showPaymentTerm: Scalars['Boolean']['output'];
  showPrices: Scalars['Boolean']['output'];
  showProductImages: Scalars['Boolean']['output'];
  showProductsProductBundle: Scalars['Boolean']['output'];
  title?: Maybe<Scalars['String']['output']>;
  totalCostPrice: Scalars['Float']['output'];
  totalDiscountPercent: Scalars['Float']['output'];
  vatPercent?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _undefined?: Maybe<Scalars['Boolean']['output']>;
  login: Scalars['String']['output'];
  projectCreate: Project;
  projectDelete?: Maybe<Scalars['Boolean']['output']>;
  projectUpdate: Project;
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationProjectCreateArgs = {
  params: ProjectCreateDto;
};


export type MutationProjectDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationProjectUpdateArgs = {
  id: Scalars['ID']['input'];
  params: ProjectUpdateDto;
};

export type NotImplemented = {
  id: Scalars['ID']['output'];
};

export enum Pagination_Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Project_List_Sort_Fields {
  Created = 'CREATED',
  Id = 'ID',
  Name = 'NAME',
  ProjectNumber = 'PROJECT_NUMBER'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  currentPage: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPrevPage: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
};

export type PaginationEdge = {
  cursor: Scalars['String']['output'];
  node: Entity;
};

export type Project = Entity & {
  __typename?: 'Project';
  city?: Maybe<City>;
  cityName?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['ID']['output'];
  /** Is heavy calculation - consider using only this when selecting single project */
  completionPercentage?: Maybe<Scalars['Float']['output']>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['ID']['output']>;
  contactPersonId?: Maybe<Scalars['ID']['output']>;
  created: Scalars['Date']['output'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  firstActivityDate?: Maybe<Scalars['Date']['output']>;
  forms: FormConnection;
  fullName: Scalars['String']['output'];
  hasFinalInvoice: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  invoiceDiscountPercent?: Maybe<Scalars['Float']['output']>;
  isFixedPrice: Scalars['Boolean']['output'];
  isOffer: Scalars['Boolean']['output'];
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  modified: Scalars['Date']['output'];
  modifiedBy?: Maybe<User>;
  modifiedById?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  notInvoicedAmount: Scalars['Float']['output'];
  offerId?: Maybe<Scalars['ID']['output']>;
  productsTotalCostPrice: Scalars['Float']['output'];
  projectFiles: ProjectFileConnection;
  projectImageUrl?: Maybe<Scalars['String']['output']>;
  projectNumber?: Maybe<Scalars['Int']['output']>;
  projectStatus: ProjectStatus;
  projectStatusId: Scalars['ID']['output'];
  project_type?: Maybe<ProjectTypeEnum>;
  streetName?: Maybe<Scalars['String']['output']>;
  totalInvoicedAmount?: Maybe<Scalars['Float']['output']>;
  totalSalesPrice: Scalars['Float']['output'];
  workingHoursTotalCostPrice: Scalars['Float']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};


export type ProjectFormsArgs = {
  params?: InputMaybe<FormsListParams>;
};


export type ProjectProjectFilesArgs = {
  params?: InputMaybe<ProjectFilesListParams>;
};

export type ProjectConnection = {
  __typename?: 'ProjectConnection';
  edges: Array<ProjectEdge>;
  pageInfo: PageInfo;
};

export type ProjectCreateDto = {
  name: Scalars['String']['input'];
};

export type ProjectEdge = PaginationEdge & {
  __typename?: 'ProjectEdge';
  cursor: Scalars['String']['output'];
  node: Project;
};

export type ProjectFile = Entity & {
  __typename?: 'ProjectFile';
  created: Scalars['String']['output'];
  createdById?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['String']['output']>;
  downloadUrl?: Maybe<Scalars['String']['output']>;
  file?: Maybe<Scalars['String']['output']>;
  fileOriginalName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
};

export type ProjectFileConnection = {
  __typename?: 'ProjectFileConnection';
  edges: Array<ProjectFileEdge>;
  pageInfo: PageInfo;
};

export type ProjectFileEdge = PaginationEdge & {
  __typename?: 'ProjectFileEdge';
  cursor: Scalars['String']['output'];
  node: ProjectFile;
};

export type ProjectFilesListParams = {
  direction?: InputMaybe<Pagination_Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  sortBy?: InputMaybe<Project_List_Sort_Fields>;
};

export type ProjectListParams = {
  direction?: InputMaybe<Pagination_Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  projectNumber?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Project_List_Sort_Fields>;
};

export type ProjectStatus = Entity & {
  __typename?: 'ProjectStatus';
  created: Scalars['String']['output'];
  deleted?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  isCustom?: Maybe<Scalars['Boolean']['output']>;
  modified: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  projectStatusType: ProjectStatusType;
  projectStatusTypeId: Scalars['ID']['output'];
};

export type ProjectStatusType = Entity & {
  __typename?: 'ProjectStatusType';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export enum ProjectTypeEnum {
  Estimate = 'estimate',
  FixedPrice = 'fixed_price',
  Hourly = 'hourly',
  Offer = 'offer'
}

export type ProjectUpdateDto = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  _undefined?: Maybe<Scalars['Boolean']['output']>;
  contact: Contact;
  contacts: ContactConnection;
  invoice: Invoice;
  me: User;
  project: Project;
  projectFile: ProjectFile;
  projectFiles: ProjectFileConnection;
  projects: ProjectConnection;
};


export type QueryContactArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContactsArgs = {
  params?: InputMaybe<ContactListParams>;
};


export type QueryInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectFilesArgs = {
  params?: InputMaybe<ProjectFilesListParams>;
};


export type QueryProjectsArgs = {
  params?: InputMaybe<ProjectListParams>;
};

export type User = {
  __typename?: 'User';
  companyId: Scalars['ID']['output'];
  created: Scalars['Date']['output'];
  deleted?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['Date']['output'];
};

export type QueryForTotalInvoiceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type QueryForTotalInvoiceQuery = { __typename?: 'Query', project: { __typename?: 'Project', id: string, totalInvoicedAmount?: number | null } };

export type InvoiceKpiDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type InvoiceKpiDataQuery = { __typename?: 'Query', invoice: { __typename?: 'Invoice', id: string, contributionRate: number, contributionMarginPerHour: number, costPriceTotal: number, salesPriceTotal: number, hourUsageAmount?: number | null, productUsageAmount?: number | null } };

export type ListProjectsTableQueryVariables = Exact<{
  params: ProjectListParams;
}>;


export type ListProjectsTableQuery = { __typename?: 'Query', projects: { __typename?: 'ProjectConnection', pageInfo: { __typename?: 'PageInfo', limit: number, hasNextPage: boolean, hasPrevPage: boolean, currentPage: number }, edges: Array<{ __typename?: 'ProjectEdge', node: { __typename?: 'Project', id: string, name: string, created: any } }> } };

export type ListProjectsTestQueryVariables = Exact<{
  params: ProjectListParams;
}>;


export type ListProjectsTestQuery = { __typename?: 'Query', projects: { __typename?: 'ProjectConnection', pageInfo: { __typename?: 'PageInfo', limit: number, hasNextPage: boolean, hasPrevPage: boolean, currentPage: number }, edges: Array<{ __typename?: 'ProjectEdge', node: { __typename?: 'Project', id: string, name: string, created: any } }> } };

export type ListProjectsQueryVariables = Exact<{
  params: ProjectListParams;
}>;


export type ListProjectsQuery = { __typename?: 'Query', projects: { __typename?: 'ProjectConnection', pageInfo: { __typename?: 'PageInfo', limit: number, hasNextPage: boolean, hasPrevPage: boolean, currentPage: number }, edges: Array<{ __typename?: 'ProjectEdge', node: { __typename?: 'Project', id: string, name: string, created: any } }> } };

export type EditProjectNameMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;


export type EditProjectNameMutation = { __typename?: 'Mutation', projectUpdate: { __typename?: 'Project', id: string, projectNumber?: number | null } };


export const QueryForTotalInvoiceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"queryForTotalInvoice"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"project"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"totalInvoicedAmount"}}]}}]}}]} as unknown as DocumentNode<QueryForTotalInvoiceQuery, QueryForTotalInvoiceQueryVariables>;
export const InvoiceKpiDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"invoiceKPIData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"invoice"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"contributionRate"}},{"kind":"Field","name":{"kind":"Name","value":"contributionMarginPerHour"}},{"kind":"Field","name":{"kind":"Name","value":"costPriceTotal"}},{"kind":"Field","name":{"kind":"Name","value":"salesPriceTotal"}},{"kind":"Field","name":{"kind":"Name","value":"hourUsageAmount"}},{"kind":"Field","name":{"kind":"Name","value":"productUsageAmount"}}]}}]}}]} as unknown as DocumentNode<InvoiceKpiDataQuery, InvoiceKpiDataQueryVariables>;
export const ListProjectsTableDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"listProjectsTable"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ProjectListParams"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"projects"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"limit"}},{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"hasPrevPage"}},{"kind":"Field","name":{"kind":"Name","value":"currentPage"}}]}},{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"created"}}]}}]}}]}}]}}]} as unknown as DocumentNode<ListProjectsTableQuery, ListProjectsTableQueryVariables>;
export const ListProjectsTestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"listProjectsTest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ProjectListParams"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"projects"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"limit"}},{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"hasPrevPage"}},{"kind":"Field","name":{"kind":"Name","value":"currentPage"}}]}},{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"created"}}]}}]}}]}}]}}]} as unknown as DocumentNode<ListProjectsTestQuery, ListProjectsTestQueryVariables>;
export const ListProjectsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"listProjects"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ProjectListParams"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"projects"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"limit"}},{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"hasPrevPage"}},{"kind":"Field","name":{"kind":"Name","value":"currentPage"}}]}},{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"created"}}]}}]}}]}}]}}]} as unknown as DocumentNode<ListProjectsQuery, ListProjectsQueryVariables>;
export const EditProjectNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"editProjectName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"projectUpdate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"projectNumber"}}]}}]}}]} as unknown as DocumentNode<EditProjectNameMutation, EditProjectNameMutationVariables>;