import { forwardRef, HTMLProps, useState } from "react";
import { Offer } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { useMount } from "~/lib/lifecycle-helpers";
import { useOrderLinesBuilder } from "~/lib/ui/order-lines/use-order-lines-builder";
import { RichTextEditor } from "~/lib/ui/rich-text-editor";
import { formatCurrency } from "~/lib/utils/number";
import { calculateLinesTotal, calculateLineTotal } from "~/lib/ui/order-lines/lib/calculations";
import { MobileViewControls } from "~/pages/p/offer/[id]/_cmp/mobile-view-controls";
import { useLocale } from "~/lib/utils/date";

type MobileViewProps = {
  offer: Offer;
  onAccept: () => void;
  onReject: () => void;
  acceptLoading: boolean;
  rejectLoading: boolean;
} & HTMLProps<HTMLDivElement>;

export const MobileView = forwardRef<HTMLDivElement, MobileViewProps>(
  (props: MobileViewProps, ref) => {
    const { t } = useTranslation();
    const { format } = useLocale();
    const { offer, onAccept, onReject, acceptLoading, rejectLoading, ...rest } = props;

    const { viewOrderLines, vat } = useOrderLinesBuilder();

    /**
     * Fetch image from url and convert to base64
     * This is needed to display the company logo, as the host of the image is weird when linking
     * directly to the image. Without this the image will be displayed 1/5 loads, with this, it works 4/5 loads.
     * @param url
     * @param callback
     */
    function toDataURL(url: string, callback: (res: string) => void) {
      const xhr = new XMLHttpRequest();
      xhr.onload = async function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result?.toString() ?? "");
        };
        await reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    }

    const [companyLogo, setCompanyLogo] = useState<string>("");

    useMount(() => {
      if (offer?.company?.file) {
        toDataURL(offer?.company?.file.fileUrl, (data) => {
          setCompanyLogo(data);
        });
      }
    });

    return (
      <div ref={ref} {...rest}>
        <div className="flex flex-col gap-6 py-6">
          <div className="flex justify-center pb-3 pt-6">
            {offer?.company && (
              <img alt={offer?.company?.name} src={companyLogo} className="h-auto max-h-12" />
            )}
          </div>
          <div className="flex flex-col gap-2 px-4 py-3">
            <div className="text-2xl">
              {t("offers:send_offer_subject", {
                type: offer?.isQuote ? t("offers:type.quote") : t("offers:type.offer"),
                title: offer.title,
              })}
            </div>
            <div>
              <div className="text-base text-shade-500">
                {`${t("offers:offer_number", { type: t(`offers:${offer?.isQuote ? "quote" : "estimate"}`) })}: ${offer?.offerNumber}`}
              </div>
              <div className="text-base text-shade-500">
                {`${t("common:expiration_date")}: ${format(offer!.expirationDate!, { shortDate: true })}`}
              </div>
            </div>
          </div>

          <MobileViewControls
            offer={offer}
            acceptLoading={acceptLoading}
            rejectLoading={rejectLoading}
            onAccept={onAccept}
            onReject={onReject}
          />

          <div className="flex flex-col gap-2">
            <div className="px-4 text-lg">{t("common:customer_details")}</div>
            <div className="flex flex-col gap-4 border-y bg-white px-4 py-3">
              <div className="flex flex-col">
                <span className="font-bold">{offer?.contact?.name}</span>
                <span className={offer?.contact?.address ? "" : "text-gray-400"}>
                  {offer?.contact?.address ? offer?.contact?.address : "Ikke tilgængelig"}
                </span>
                <span
                  className={
                    offer?.contact?.zipCode && offer?.contact?.cityName ? "" : "text-gray-400"
                  }
                >
                  {offer?.contact?.zipCode && offer?.contact?.cityName
                    ? `${offer?.contact?.zipCode} ${offer?.contact?.cityName}`
                    : t("common:not_available")}
                </span>
              </div>
              {offer?.contactPerson && (
                <div>
                  <span className="font-bold">
                    {t("common:att")}:{" "}
                    <span className="font-normal">{offer?.contactPerson?.name}</span>
                  </span>
                </div>
              )}

              <div>
                <span className="font-bold">{t("common:work_address")}: </span>
                <span className="font-normal">
                  {`${offer?.address}, ${offer?.city?.zipCode} ${offer?.city?.name}`}
                </span>
              </div>
            </div>
          </div>

          {!!offer?.description && (
            <div className="flex flex-col gap-2">
              <div className="px-4 text-lg">{t("common:description")}</div>
              <div className="border-y bg-white px-4 py-3">
                <RichTextEditor
                  name="offer-description"
                  initialData={offer?.description ?? ""}
                  preview={true}
                />
              </div>
            </div>
          )}

          <div className="flex flex-col gap-2">
            <div className="px-4 text-lg">
              {offer?.isQuote ? t("offers:type.quote") : t("offers:type.offer")}
            </div>
            <div className="border-y bg-white py-2">
              <div className="flex flex-col divide-y px-4 text-sm">
                <div className="flex py-2 font-semibold">
                  <div className="line-clamp-2 w-10/24 shrink">
                    {t("common:product", { count: 1 })}
                  </div>
                  <div className="w-3/12 shrink-0 text-center">{t("common:quantity")}</div>
                  <div className="w-4/12 shrink-0 text-right">{t("common:amount")}</div>
                </div>
                {viewOrderLines.map((line, index) =>
                  line.type === "text" ? (
                    <div key={`${line.id}-${index}`}>
                      <RichTextEditor
                        name={`order-line-text-${line.identifier}`}
                        initialData={line.description}
                        preview={true}
                      />
                    </div>
                  ) : (
                    <div key={`${line.id}-${index}`} className="flex py-2">
                      <div className="line-clamp-2 w-10/24 shrink pr-2">{line.name}</div>
                      <div className="w-3/12 shrink-0 text-center">{line.quantity}</div>
                      <div className="w-4/12 shrink-0 text-right">
                        {formatCurrency(calculateLineTotal(line) ?? 0, { currency: "DKK" })}
                      </div>
                    </div>
                  )
                )}
              </div>

              <div className="pt-8 text-sm text-gray-900">
                <div className="flex justify-end pr-3">
                  <div className="flex border-b">
                    <div className="flex flex-col items-end gap-1">
                      <span className="pr-16">{t("finance:subtotal")}</span>

                      <span className="flex items-center gap-2 pb-2 pr-16">
                        {`${t("common:vat")} (${vat}%)`}
                      </span>
                    </div>
                    <div className="flex flex-col items-end gap-1 pb-2">
                      <span>
                        {formatCurrency(calculateLinesTotal(viewOrderLines, "subAmount", vat))}
                      </span>

                      <div className="flex-grow-1 flex h-full items-center">
                        {formatCurrency(calculateLinesTotal(viewOrderLines, "vatAmount", vat))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end pr-3">
                  <div className="flex flex-col items-end gap-1">
                    <span className="w-full pr-16 pt-2 text-right font-semibold">
                      {t("finance:total")}
                    </span>
                  </div>
                  <div className="flex flex-col items-end gap-1">
                    <span className=" pt-2 font-semibold">
                      {formatCurrency(calculateLinesTotal(viewOrderLines, "totalAmount", vat))}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <MobileViewControls
            offer={offer}
            acceptLoading={acceptLoading}
            rejectLoading={rejectLoading}
            onAccept={onAccept}
            onReject={onReject}
            hideLabel={true}
          />

          <div className="flex flex-col items-center pt-8">
            <span className="font-bold">{offer?.company?.name}</span>
            <span>{offer?.company?.streetName}</span>
            <span>
              {offer?.company?.city?.zipCode} {offer?.company?.city?.name}
            </span>
            <span>
              {t("common:cvr_abbr")}: {offer?.company?.cvr}
            </span>
            <span>
              {t("common:phone_number_abbr")}: +{offer?.company?.phoneCountrycode}{" "}
              {offer?.company?.phone}
            </span>
            <span>{offer?.company?.contactEmail}</span>
          </div>
        </div>
      </div>
    );
  }
);

MobileView.displayName = "MobileView";
