import {
  UpdateUserIntegrationSettingRequest,
  UpdateUserIntegrationSettingRequestIntegrationSettingsInner,
  User,
} from "@apacta/sdk";
import { PageSpinner } from "~/lib/ui/page-spinner";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useToasts } from "~/lib/toast/use-toasts";
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { usePageTitle } from "~/lib/navigation/use-page-title";
import { useOutletContext } from "react-router-dom";
import { useMount } from "~/lib/lifecycle-helpers";
import { Suspense, useState } from "react";
import { captureException } from "@sentry/core";
import { TimeEntryRuleGroupsInput } from "~/pages/employees/_cmp/time_entry_rule_groups";
import { useFormState } from "~/lib/form-state";
import { userSettingsSchema } from "~/pages/employees/_cmp/user-settings-schema";
import { Button, Label } from "~/lib/ui";
import { Checkbox, TextInput } from "~/lib/ui/form-elements";
import { IntegrationTypes } from "~/pages/employees/_cmp/integration_types";
import { ComboboxListItem } from "~/lib/ui/form-elements/types";
import { ProjectSelection } from "~/lib/ui/selection-combobox/project-selection";
import { SelectionCombobox } from "~/lib/ui/selection-combobox";
import { Timepicker } from "~/lib/ui/timepicker";

export default function SettingsTab() {
  const pageTitle = usePageTitle();
  const { user } = useOutletContext<{ user: User }>();
  const api = useAPI();
  const { t } = useTranslation();
  const { id: userId } = useParams();
  const [isSettingModified, setIsSettingModified] = useState(false);
  const { data, isLoading } = useSuspenseQuery({
    queryKey: ["users", userId],
    queryFn: () => api.getIntegrationTypes({ userId: userId as string }),
  });
  const toast = useToasts();
  useMount(() => {
    pageTitle.set(`${user?.fullName} | ${t("common:user", { count: 2 })}`);
  });

  const projectsUsers = () => {
    if (user.projects === undefined || user.projects?.length < 1) {
      return [];
    }
    return user?.projects?.map((p) => {
      return p.id;
    });
  };

  const queryClient = useQueryClient();

  const { isValid, isModified, getValue, getValues, register, onChange, resetInitialValues } =
    useFormState({
      schema: userSettingsSchema,
      initialValues: {
        adminAccess: user.adminAccess,
        timeEntryRuleGroupId: user.timeEntryRuleGroupId,
        receiveFormMails: user.receiveFormMails ?? "",
        projectIds: projectsUsers(),
      },
    });

  const userEdit = useMutation({
    mutationFn: () =>
      api.editUser({
        userId: userId as string,
        editUserRequest: {
          timeEntryRuleGroupId: getValue("timeEntryRuleGroupId") ?? null,
          adminAccess: getValue("adminAccess"),
          projectIds: getValue("projectIds") ?? undefined,
        },
      }),
    onSuccess: () => {
      toast.showTemplate("CHANGES_SAVED");
      queryClient.invalidateQueries({
        queryKey: ["user", userId],
      });
      resetInitialValues(getValues());
    },
  });

  const handleSaveUserSettings = () => {
    if (userId) {
      userEdit.mutate();
    }
    updateM.mutate({
      integrationSettings: userIntegrationSetting,
    });
  };

  const [userIntegrationSetting, setUserIntegrationSetting] = useState(
    Array<UpdateUserIntegrationSettingRequestIntegrationSettingsInner>
  );

  const adminAccessOptions = (() => {
    if (!user) return [];
    return user.adminAccessOptions?.map((accessOption) => optionToListItem(accessOption)) || [];
  })();

  function optionToListItem(a: string): ComboboxListItem {
    return {
      id: a,
      label: t("users:" + a),
      value: a,
    };
  }

  const updateM = useMutation({
    mutationFn: (args: UpdateUserIntegrationSettingRequest) =>
      api.updateUserIntegrationSetting({
        userId: user.id,
        updateUserIntegrationSettingRequest: args,
      }),
    onSuccess: () => {
      toast.showTemplate("CHANGES_SAVED");
    },
    onError: (err: Error) => {
      toast.showTemplate("UNEXPECTED_ERROR");
      captureException(new Error("Failed to populate entity list", { cause: err }));
    },
  });
  if (isLoading) {
    return (
      <div className="relative h-96 w-full">
        <PageSpinner loadingMessage={t("customers:contacts.list.loading")} />
      </div>
    );
  }

  return (
    <>
      <div className="flex w-full flex-col gap-12">
        <Suspense
          fallback={
            <div className="relative h-96 w-full">
              <PageSpinner loadingMessage={t("common:loading")} />
            </div>
          }
        >
          <>
            <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
              <h2 className="m-0">{t("users:tabs.settings")}</h2>
              <div>
                <Button
                  onClick={() => handleSaveUserSettings()}
                  variant="tertiary"
                  disabled={!isModified || !isValid}
                >
                  {t("common:save_changes")}
                </Button>
              </div>
            </div>
            <div className="flex h-full flex-col gap-8">
              <div className="flex flex-col gap-8">
                <div className="flex-1">
                  <div className="mb-2 text-gray-500">{t("users:tabs.primary_label")}</div>
                  <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
                    <div className="flex flex-1 flex-col gap-6 md:basis-1/2">
                      <div>
                        <SelectionCombobox
                          required
                          label={t("users:access_to_admin")}
                          defaultValue={
                            adminAccessOptions?.find((i) => i.value === user.adminAccess)?.id
                          }
                          data={adminAccessOptions}
                          valueFn={(e) => e.value}
                          labelFn={(e) => e.label}
                          onSelect={(v) => onChange("adminAccess", v ?? undefined)}
                        />
                      </div>
                      <div className="sm:col-span">
                        <div className="mt-2">
                          <TimeEntryRuleGroupsInput
                            {...register("timeEntryRuleGroupId")}
                            onChange={(groupId) => {
                              onChange("timeEntryRuleGroupId", groupId ?? undefined);
                            }}
                            initialEntryId={getValue("timeEntryRuleGroupId")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col gap-6 md:basis-1/2">
                      <TextInput
                        label={t("users:receive_form_mails_placeholder")}
                        {...register("receiveFormMails")}
                        placeholder={t("users:receive_form_mails")}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex-1">
                  <div className="mb-2 text-gray-500"> {t("projects:project_data")}</div>
                  <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
                    <div className="flex flex-1 flex-col gap-6">
                      <div className="sm:flex sm:gap-4">
                        <ProjectSelection
                          multiple={true}
                          defaultValue={getValue("projectIds")}
                          onSelect={(val) => onChange("projectIds", val)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {user.mobileAppPreferences ? (
                  <div className="flex-1">
                    <div className="mb-2 text-gray-500">
                      {" "}
                      {t("users:notifications_settings", "Notification settings for employee")}
                    </div>
                    <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
                      <div className="flex flex-1 flex-col gap-6">
                        <div className="sm:flex sm:gap-2">
                          <Checkbox
                            id="empoyee-notification-task"
                            className="inline disabled:hover:border-gray-300 disabled:hover:bg-gray-200"
                            disabled={true}
                            checked={user.mobileAppPreferences.enableNotificationsTasks}
                          />
                          <label htmlFor="empoyee-notificanpption-task">{t("common:Tasks")}</label>
                        </div>
                        <div className="sm:flex sm:gap-2">
                          <Checkbox
                            id="employee-form-reminders"
                            className="inline disabled:hover:border-gray-300 disabled:hover:bg-gray-200"
                            disabled={true}
                            checked={
                              user.mobileAppPreferences.enableNotificationsFormsDailyReminder
                            }
                          />
                          <label htmlFor="employee-form-reminders">
                            {t("common:form_reminders", "Form reminders")}
                          </label>
                        </div>
                        {user.mobileAppPreferences.enableNotificationsFormsDailyReminder && (
                          <div className="flex-wrap sm:flex sm:gap-2">
                            {user.mobileAppPreferences.formsDailyReminderPreferences &&
                              Object.entries(
                                user.mobileAppPreferences.formsDailyReminderPreferences
                              ).map(([key, value]) => (
                                <div className="max-w-[8rem]" key={key}>
                                  <Label htmlFor={key} className="mb-2 block text-sm font-medium">
                                    {t(`common:${key}`)}
                                  </Label>
                                  <Timepicker value={value} disabled={true} />
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <IntegrationTypes
              user={user}
              onChange={(value) => {
                setUserIntegrationSetting(value);
              }}
            />
          </>
        </Suspense>
      </div>
    </>
  );
}
