import { useOutletContext } from "react-router";
import { InvoiceOutlet } from "../[invoiceId]";
import { useTranslation } from "react-i18next";
import { CustomerSelection } from "~/lib/ui/selection-combobox/customer-selection";
import DateInput from "~/lib/ui/calendar/date-input";
import { Button, getIcon, LabelInput } from "~/lib/ui";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import { EditInvoiceOperationRequest, InvoiceLine } from "@apacta/sdk";
import { OrderLinesBuilder } from "~/lib/ui/order-lines/order-lines-builder";
import OrderLinesBuilderProvider from "~/lib/ui/order-lines/order-lines-builder-context";
import { invoiceLineToOrderLine } from "../../../lib/ui/order-lines/lib/transformers/invoiceline-to-orderline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { CACHE_INVOICES } from "..";
import { orderLineToInvoiceLine } from "../../../lib/ui/order-lines/lib/transformers/orderline-to-invoiceline";
import { OrderLinesBuilderOnChangeReturn } from "~/lib/ui/order-lines/lib/types";
import { useCallback, useEffect } from "react";
import { RichTextEditor } from "~/lib/ui/rich-text-editor";
import { useMe } from "~/lib/auth/use-me";
import { PaymentConditionSelection } from "~/pages/customers/_cmp/payment-condition-selection";
import { CACHE_PROJECTS } from "~/pages/projects";

const INVOICE_DESCRIPTION_CHARACTER_LIMIT = 248;

export function InvoiceEditPage() {
  const { t } = useTranslation();
  const api = useAPI();
  const me = useMe();
  const queryClient = useQueryClient();
  const { invoice, kpi } = useOutletContext<InvoiceOutlet>();
  const formState = useFormState({
    schema: {
      customerId: z.string().optional(),
      issueDate: z.date().optional(),
      reference: z.string().optional(),
      message: z.string().optional(),
      lines: z.array(z.custom<InvoiceLine>((line) => line)),
      paymentTermId: z.string().optional(),
    },
    initialValues: {
      customerId: invoice.contactId,
      issueDate: invoice.issuedDate,
      reference: invoice.reference,
      message: invoice.message,
      lines: invoice.invoiceLines ?? [],
      paymentTermId: invoice.paymentTermId,
    },
  });

  const values = formState.getValues();

  const handleAddLine = (d: OrderLinesBuilderOnChangeReturn) => {
    const invoiceLines = d.orderLines.map((v) => orderLineToInvoiceLine(v, invoice.id));
    formState.setValues({ lines: invoiceLines }, d.isInitial);
  };

  const mut = useMutation({
    mutationFn: (args: EditInvoiceOperationRequest) => {
      return api.editInvoice(args);
    },
  });

  const handleSave = useCallback(async () => {
    await mut.mutateAsync({
      invoiceId: invoice.id,
      editInvoiceRequest: {
        contactId: values.customerId,
        issuedDate: values.issueDate,
        reference: values.reference,
        message: values.message,
        invoiceLines: values.lines,
        paymentTermId: values.paymentTermId,
      },
    });
    await queryClient.invalidateQueries({ queryKey: [CACHE_INVOICES, invoice.id] });
    // This makes sure the project is updated with the new invoice
    await queryClient.invalidateQueries({ queryKey: [CACHE_PROJECTS, invoice.projectId] });
  }, [invoice, values]);

  // If the invoice changes (like from saving and refetching), update the form state
  useEffect(() => {
    formState.setValues(
      {
        customerId: invoice.contactId,
        issueDate: invoice.issuedDate,
        reference: invoice.reference,
        message: invoice.message,
        lines: invoice.invoiceLines ?? [],
      },
      true
    );
  }, [invoice]);

  return (
    <div>
      <TabHeading
        actionArea={
          <div className="flex flex-row gap-4">
            <Button onClick={handleSave} disabled={!formState.isModified} variant="primary">
              {t("common:save")}
            </Button>
            <Button Icon={getIcon("invoice")} variant="secondary">
              {t("invoices:action_book", "Book")}
            </Button>
          </div>
        }
      >
        {t("common:details")}
      </TabHeading>
      <div className="rounded-md border border-gray-300 bg-white p-8">
        <div className="mb-4 flex w-full flex-row flex-wrap justify-between gap-8">
          <div className="w-full flex-1">
            <CustomerSelection
              controlled
              value={formState.getValue("customerId")}
              onSelect={(id) => id && formState.onChange("customerId", id)}
            />
          </div>

          <div className="flex flex-1 flex-col gap-4">
            <DateInput
              label={t("invoices:invoice_date")}
              value={formState.getValue("issueDate")}
              onChange={(v) => formState.onChange("issueDate", v)}
            />
            <LabelInput
              {...formState.registerStringInput("reference")}
              label={t("common:reference")}
            />
          </div>
        </div>
        <RichTextEditor
          initialData={formState.getValue("message")}
          onChange={(text, html, valid) => formState.onChange("message", text)}
          characterLimit={INVOICE_DESCRIPTION_CHARACTER_LIMIT}
          disableRichText={true}
        />

        <div className="mt-4">
          <OrderLinesBuilderProvider
            data={formState.getValue("lines")}
            transformerFn={invoiceLineToOrderLine}
            onChange={handleAddLine}
            initialVatPercent={me.company.vatPercent ?? 25}
          >
            <OrderLinesBuilder
              editMode={true}
              hideProductBundleContent={true}
              buttonSizes="small"
              usePlainTextEditor={true}
              options={{
                orderLinesActionVisibility: {
                  showAddProduct: true,
                  showAddHours: true,
                  showAddProductBundle: false,
                  showAddText: true,
                  showSubtotal: true,
                  showVAT: true,
                  showTotal: true,
                  sumCostPrice: false,
                },
                columns: {
                  product: {
                    visible: true,
                  },
                },
              }}
              companyVatPercent={me.company.vatPercent}
            />
          </OrderLinesBuilderProvider>
        </div>
        <div className="max-w-[20em]">
          <PaymentConditionSelection
            initialConditionId={invoice.contact?.paymentTermId ?? undefined}
            onChange={(v) => formState.onChange("paymentTermId", v?.id)}
          />
        </div>
      </div>
      <div className="mt-6 rounded-md border border-gray-300 bg-white p-6 text-center">
        <div>{`${me.company.name} - ${me.company.streetName} - ${me.company.city?.zipCode}, ${me.company.city?.name}`}</div>
        <div>
          {`Phone: ${me.company.phone}`} -{" "}
          {`Email: ${me.company.invoiceEmail ?? me.company.contactEmail}`}
        </div>
      </div>
    </div>
  );
}
