import { twMerge } from "tailwind-merge";
import { ChatBubbleBottomCenterIcon } from "@heroicons/react/24/outline";
import { RichTextEditor, RichTextEditorRef, ToolbarAction } from "~/lib/ui/rich-text-editor";
import { OrderLine } from "~/lib/ui/order-lines/lib/types";
import { useRef, useState } from "react";
import { useOrderLinesBuilder } from "~/lib/ui/order-lines/use-order-lines-builder";
import TemplateModal from "~/pages/offers/_cmp/template-modal";
import { Dialog } from "~/lib/ui";
import { useTranslation } from "react-i18next";

export function OrderLinesBuilderTextRow({
  line,
  editMode,
  depth,
  usePlainTextEditor,
}: {
  line: Partial<OrderLine>;
  editMode: boolean;
  depth: number;
  usePlainTextEditor: boolean;
}) {
  const { updateLine } = useOrderLinesBuilder();
  const { t } = useTranslation();

  const editorRef = useRef<RichTextEditorRef>(null);
  const handleEditorChange = async (validState: boolean) => {
    if (editorRef.current) {
      const markdown = await editorRef.current.getEditorMarkdown();
      updateLine(
        ["isInvalid", "description"],
        { isInvalid: markdown.length > 2000 || !validState, description: markdown },
        line.identifier
      );
    }
  };

  const toolbarActions: Array<ToolbarAction> = [
    {
      render: () => <span>{t("common:template", { count: 2 })}</span>,
      onClick: () => setShowModal(true),
    },
  ];

  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <Dialog
        open={showModal}
        onOpenChange={setShowModal}
        render={({ onClose }) => (
          <TemplateModal
            onClose={() => {
              setShowModal(false);
              onClose();
            }}
            onSubmit={(data) => {
              editorRef.current?.setEditorMarkdown(data, true);
              setShowModal(false);
              onClose();
            }}
          />
        )}
      />
      <div
        style={{ paddingLeft: `calc(1rem * ${1 + depth})` }}
        className={twMerge(editMode ? "w-22/24" : "w-full", "flex flex-shrink gap-4 py-5 pr-3")}
      >
        {editMode && (
          <div className="py-2">
            <ChatBubbleBottomCenterIcon className="h-6 w-6 text-gray-400" />
          </div>
        )}
        <div className="w-full">
          <RichTextEditor
            name={`order-line-text-${line.identifier}`}
            toolbarActions={toolbarActions}
            ref={editorRef}
            initialData={line.description}
            onChange={(text, html, valid) => handleEditorChange(valid)}
            preview={!editMode}
            characterLimit={2000}
            disableRichText={usePlainTextEditor}
          />
        </div>
      </div>
    </>
  );
}
