import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { getIcon } from "~/lib/ui";
import { useCallback, useState } from "react";
import { useToasts } from "~/lib/toast/use-toasts";
import { LocationType, LocationView } from "~/lib/json-forms/components/location-view";
import { useMe } from "~/lib/auth/use-me";
import { parse } from "tinyduration";
import { useMount } from "~/lib/lifecycle-helpers";
import { filterHours } from "~/lib/utils/time";
import { DurationSelector } from "~/pages/time-registration/_cmp/duration-selector";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

type PropsCommon = {
  onCustomerCreated?: (customerId: string) => void;
  onOpenChange?: (isOpen: boolean) => void;
  projectId: string;
  userCheckedIn: boolean;
  onClose: () => void;
};

export function CheckInDialog(props: PropsCommon) {
  const { t } = useTranslation();
  const api = useAPI();
  const me = useMe();
  const toast = useToasts();
  const [loading, setLoading] = useState(false);
  const [locationData, setLocationData] = useState<LocationType>({ latitude: 0, longitude: 0 });
  const [currentDuration, setCurrentDuration] = useState<string>("PT0H0M");
  const [checkInTime, setCheckInTime] = useState<Date>(new Date());
  const [hoursIntervals, setHoursIntervals] = useState<Array<number>>(
    props.userCheckedIn
      ? filterHours(new Date().getHours(), false)
      : filterHours(new Date().getHours(), true)
  );
  let coordinates: LocationType = { latitude: 0, longitude: 0 };
  const isInvalid = loading || (locationData.latitude === 0 && locationData.longitude === 0);

  async function detectLocation() {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const currentPosition = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        setLocationData(currentPosition);
        coordinates = currentPosition;

        setLoading(false);
      },
      () => {
        toast.showTemplate("OPERATION_FAILED", {
          title: t("forms:location_error"),
          description: t("forms:location_error_description"),
        });
        setLoading(false);
      }
    );
  }
  useMount(() => {
    detectLocation();
  });

  const handleCheckIn = useCallback(async () => {
    try {
      await api.checkIn({
        checkInRequest: {
          projectId: props.projectId,
          checkedIn: checkInTime,
          checkinLatitude: coordinates.latitude > 0 ? coordinates.latitude.toString() : null,
          checkinLongitude: coordinates.longitude > 0 ? coordinates.longitude.toString() : null,
        },
      });
      toast.showTemplate("CHANGES_SAVED");
      props.onClose();
    } catch (err) {
      toast.show({
        title: t("forms:check_in_error_title"),
        description: t("forms:check_in_error_description"),
        Icon: ExclamationCircleIcon,
        variant: "error",
        timeout: 5000,
      });
    }
  }, []);

  const handleCheckOut = useCallback(async () => {
    try {
      await api.checkOut({
        checkOutRequest: {
          checkedOut: checkInTime,
          checkoutLatitude: coordinates.latitude > 0 ? coordinates.latitude.toString() : null,
          checkoutLongitude: coordinates.longitude > 0 ? coordinates.longitude.toString() : null,
        },
      });
      toast.showTemplate("CHANGES_SAVED");
      props.onClose();
    } catch (err) {
      toast.show({
        title: t("forms:check_out_error_title"),
        description: t("forms:check_out_error_description"),
        Icon: ExclamationCircleIcon,
        variant: "error",
        timeout: 5000,
      });
    }
  }, []);

  function handleChange(duration: string, key?: string) {
    if (key === undefined) {
      return;
    }
    setCurrentDuration(duration);
    const parsedTime = parse(duration);
    const now = checkInTime;
    switch (key) {
      case "minutes":
        now.setMinutes(parsedTime.minutes ?? 0);
        break;
      case "hours":
        now.setHours(parsedTime.hours ?? 0);
        break;
    }
    setCheckInTime(now);
  }

  return (
    <>
      <DialogHeader
        title={
          props.userCheckedIn
            ? t("common:confirm_check_out", "Confirm check out")
            : t("common:confirm_check_in", "Confirm check in")
        }
        Icon={getIcon("location")}
      />
      <LocationView loading={loading} location={locationData} label="location" />
      {me.companySettings.checkAtSpecificTime ? (
        <>
          <DurationSelector
            value={parse(currentDuration)}
            label={props.userCheckedIn ? t("common:check_out") : t("common:check_in")}
            onChangeString={(dur, key) => handleChange(dur, key)}
            hoursOptions={hoursIntervals}
            minuteOptions={[0, 15, 30, 45]}
          />
          <div className="text-gray-500">
            {props.userCheckedIn
              ? t("common:select_past_time", "You can only select a time in the past")
              : t("common:select_future_time", "You can only select a time in the future")}
          </div>
        </>
      ) : null}
      <DialogFooter
        onClose={() => {
          props.onClose();
        }}
        primary={{
          label: props.userCheckedIn ? t("common:check_out") : t("common:check_in"),
          onClick: props.userCheckedIn ? handleCheckOut : handleCheckIn,
          disabled: isInvalid,
        }}
      />
    </>
  );
}
