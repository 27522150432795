import { InvoiceLine } from "@apacta/sdk";
import { OrderLine } from "~/lib/ui/order-lines/lib/types";

export const invoiceLineToOrderLine = (line: InvoiceLine, index: number): Partial<OrderLine> => {
  if (line.id === undefined) {
    console.error("Invoice line is missing an ID", line);
  }
  const base: Partial<OrderLine> = {
    id: line.id,
    identifier: line.id,
    productId: line.productId,
    quantity: line.quantity,
    sellingPrice: line.sellingPrice,
    costPrice: line.buyingPrice ?? 0,
    discount: line.discountPercent,
    discountType: "percent",
    description: line.description,
    position: index ?? 0,
    name: line.name,
    usersPriceGroupId: line.usersPriceGroupId,
    vendorProductId: line.vendorProductId,
    expenseLineId: line.expenseLineId,
  } as const;

  if (line.type === "text") {
    return {
      ...base,
      description: line.name, // line.name is description for text lines
      type: "text",
    };
  }
  // Bundle support is not implemented. This is a placeholder for now.

  // Hours
  if (line.type === "user" || !!line.usersPriceGroupId) {
    // line.type should be "user", but isn't for some older lines
    return {
      ...base,
      type: "hours",
    };
  }

  // Product (fallback)
  return {
    ...base,
    type: "product",
  };
};
