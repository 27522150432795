import { z } from "zod";
import { UserAdminAccessEnum } from "@apacta/sdk";

export const addUserSchema = {
  first_name: z.string().nonempty().max(255),
  last_name: z.string().max(255).optional(),
  email: z.string().max(255).email().optional(),
  mobile_country_code: z.string().max(8).optional(),
  mobile_phone: z.string().min(6).max(32).optional(),
  admin_access: z.nativeEnum(UserAdminAccessEnum).default(UserAdminAccessEnum.NoAccessToAdmin),
  users_price_group_id: z.string().uuid().optional(),
  cost_price: z.number().optional(),
  extra_price: z.number().optional(),
  labels: z.array(z.string().uuid()),
};
