import {
  ForwardedRef,
  forwardRef,
  Fragment,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { useTranslation } from "react-i18next";
import { OrderLinesBuilderTableDragLayer } from "~/lib/ui/order-lines/table/order-lines-builder-table-drag-layer";
import {
  LineRef,
  OrderLinesBuilderTableRow,
} from "~/lib/ui/order-lines/table/order-lines-builder-table-row";
import { OrderlinesBuilderTableFooter } from "~/lib/ui/order-lines/table/orderlines-builder-table-footer";
import { useOrderLinesBuilder } from "~/lib/ui/order-lines/use-order-lines-builder";
import {
  OrderLine,
  OrderLineAdd,
  OrderLineColumnOptions,
  OrderLinesBuilderRef,
  OrderLinesBuilderTableProps,
  OrderLinesColumns,
} from "~/lib/ui/order-lines/lib/types";
import { flushSync } from "react-dom";

export const OrderLinesBuilderTable = forwardRef<OrderLinesBuilderRef, OrderLinesBuilderTableProps>(
  OrderLinesBuilderTableInner
);

export function OrderLinesBuilderTableInner(
  {
    lines,
    isChild = false,
    depth = 0,
    parentId,
    editMode = false,
    companyVatPercent,
    showAll,
    columns,
    orderLinesActionVisibility,
    overwriteSubAmount,
    hideProductBundleContent = false,
    maxHeight,
    buttonSizes = "medium",
    popoverOptions,
    usePlainTextEditor,
  }: OrderLinesBuilderTableProps,
  ref: ForwardedRef<OrderLinesBuilderRef>
) {
  const [focusLineId, setFocusLineId] = useState<string>();
  const { vat, addLine, isModified, isValid, resetInitialValues, setViewOrderLines } =
    useOrderLinesBuilder();
  const lineRefs = useRef<{ [key: string]: LineRef }>({});
  const [hasDiscountedLines, setHasDiscountedLines] = useState<boolean>(false);
  const defaultColumnOptions: OrderLinesColumns = {
    product: {
      visible: true,
    },
    quantity: {
      visible: true,
    },
    costPrice: {
      visible: true,
    },
    salePrice: {
      visible: true,
    },
    discount: {
      visible: true,
    },
    total: {
      visible: true,
    },
    actions: {
      visible: true,
    },
    handle: {
      visible: true,
    },
  };
  const columnOptions = {
    ...defaultColumnOptions,
    ...columns,
  };

  //TODO - Move the entire view portion to its own component for display only

  useImperativeHandle(ref, () => ({
    getLines: () => lines,
    getVat: () => vat,
    isModified: () => isModified,
    isValid: () => isValid,
    resetInitialValues: () => resetInitialValues(),
    setViewLines: (l: Array<Partial<OrderLine>>) => flushSync(() => setViewOrderLines(l)),
  }));

  const { t } = useTranslation();

  const handleAddOfferLine = (opts: OrderLineAdd) => {
    const details = addLine(opts);
    if (details.isDuplicate) {
      // If the line is a duplicate, focus the line immediately
      lineRefs.current[details.focusLineId]?.focus(true);
    } else {
      // If the line is not a duplicate, set the focusLineId to be focused after the render
      setFocusLineId(details.focusLineId);
    }
  };

  useLayoutEffect(() => {
    // Focus the newly added line after the render
    if (focusLineId) {
      const lineRef = lineRefs.current[focusLineId];
      if (lineRef) {
        lineRef.focus();
        setFocusLineId(undefined);
      }
    }
    // Check if the lines contain any discounted lines
    setHasDiscountedLines(lines.some((line) => !!line.discount));
  }, [lines]);

  const handleRef = (lineRef: LineRef | null, line: Partial<OrderLine>) => {
    if (lineRef) {
      lineRefs.current[line.identifier as string] = lineRef;
    }
  };

  // Extracted weights from each column for calculating width (former w-[Weight]/24)
  const weights: Record<keyof OrderLinesColumns, number> = {
    handle: editMode ? 1 : 0,
    product: editMode ? 7 : 9,
    quantity: 2,
    costPrice: 3,
    salePrice: editMode ? 3 : 5,
    discount: editMode ? 4 : 3,
    total: 3,
    actions: editMode ? 1 : 0,
  };

  const columnWidths = enrichColumnsWithWidth(columnOptions, weights);

  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <OrderLinesBuilderTableDragLayer />
      <div className="flex w-full flex-col">
        {!isChild && (
          <div
            className="flex w-full border-b border-gray-300 font-semibold"
            data-pdf-rule="repeat-on-split"
          >
            {editMode && columnOptions.handle.visible && <div className="w-1/24 p-3"></div>}
            {columnOptions?.product.visible && (
              <div className="p-3" style={{ width: columnWidths["product"].width }}>
                {t("common:product", { count: 1 })}
              </div>
            )}
            {columnOptions?.quantity.visible && (
              <div className="p-3 text-right" style={{ width: columnWidths["quantity"].width }}>
                {t("common:quantity")}
              </div>
            )}
            {columnOptions?.quantity.visible && editMode && (
              <div
                className="p-3 text-right"
                style={{ width: columnWidths["costPrice"].width }}
              >{`${t("common:cost_price")} (${t("common:each_abbr")})`}</div>
            )}
            {columnOptions?.salePrice.visible && (
              <div className="p-3 text-right" style={{ width: columnWidths["salePrice"].width }}>
                {`${t("common:price")} (${t("common:each_abbr")})`}
              </div>
            )}
            {columnOptions?.discount.visible && (editMode || hasDiscountedLines) && (
              <div className="p-3 text-right" style={{ width: columnWidths["discount"].width }}>
                {t("common:discount")}
              </div>
            )}

            {columnOptions?.total.visible && (
              <div className="flex flex-grow justify-end p-3 text-right">{t("common:amount")}</div>
            )}
            {editMode && (
              <div
                style={{ width: columnWidths["actions"].width }}
                className={twMerge("p-3", columnOptions?.total.visible ? "" : "grow")}
              ></div>
            )}
          </div>
        )}
        <div
          className={twMerge(!!maxHeight ? maxHeight : "", "planning-scrollbar overflow-y-auto")}
        >
          {lines.map((line) => {
            const showChildren =
              !hideProductBundleContent &&
              !!line.childOfferLines &&
              line.childOfferLines.length > 0 &&
              (editMode || !!showAll);
            return (
              <Fragment key={`offer-line-${line.identifier}`}>
                <OrderLinesBuilderTableRow
                  ref={(r) => handleRef(r, line)}
                  line={line}
                  editMode={editMode}
                  depth={depth}
                  parentId={parentId}
                  columns={
                    hasDiscountedLines || editMode
                      ? columnOptions
                      : {
                          ...columnOptions,
                          discount: {
                            ...columnOptions.discount,
                            visible: false,
                          },
                        }
                  }
                  columnWidths={columnWidths}
                  usePlainTextEditor={usePlainTextEditor}
                />
                {showChildren && line.childOfferLines ? (
                  <OrderLinesBuilderTable
                    lines={line.childOfferLines}
                    parentId={line.identifier}
                    isChild={true}
                    depth={depth + 1}
                    editMode={editMode}
                    columns={columnOptions}
                    usePlainTextEditor={usePlainTextEditor}
                  />
                ) : null}
              </Fragment>
            );
          })}
        </div>
        {!isChild && (
          <div data-pdf-rule="no-split">
            <OrderlinesBuilderTableFooter
              editMode={editMode}
              onAddOfferLine={handleAddOfferLine}
              companyVatPercent={companyVatPercent}
              options={{ orderLinesFooterVisibility: orderLinesActionVisibility, popoverOptions }}
              overwriteSubAmount={overwriteSubAmount}
              buttonSizes={buttonSizes}
            />
          </div>
        )}
      </div>
    </DndProvider>
  );
}
export interface OrderLineColumnWithWidth extends OrderLineColumnOptions {
  width: string;
}

function enrichColumnsWithWidth(
  columns: Record<keyof OrderLinesColumns, OrderLineColumnOptions>,
  weights: Record<keyof OrderLinesColumns, number>
): Record<keyof OrderLinesColumns, OrderLineColumnWithWidth> {
  // Calculate total weight
  const totalWeight = Object.entries(columns)
    .filter(([, c]) => !!c.visible)
    .reduce((total, [key]) => total + weights[key as keyof OrderLinesColumns], 0);

  // Enrich columns with width
  const enrichedColumns: Record<string, OrderLineColumnWithWidth> = {};

  for (const [key, column] of Object.entries(columns)) {
    const width = (weights[key as keyof OrderLinesColumns] / totalWeight) * 100;
    enrichedColumns[key] = {
      ...column,
      width: `${width.toFixed(2)}%`,
    };
  }

  return enrichedColumns;
}
