import {
  ChangeStatusOperationRequest,
  UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInner,
  UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner,
  UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner,
} from "@apacta/sdk";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { Fragment, useMemo } from "react";
import { useAPI } from "~/lib/api";
import { Checkbox } from "~/lib/ui/form-elements";
import { useLocale } from "~/lib/utils/date";
import { isWeekend } from "date-fns";
import { usePlanning } from "~/lib/planning";
import { useHolidays } from "~/lib/calendar/use-holidays";
import { linkToFormNew, linkToProject } from "~/lib/utils";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import Tooltip from "~/lib/ui/tooltip";
import { twMerge } from "tailwind-merge";
import { Spinner } from "~/lib/ui/spinner";

export function RenderProjectsTableColumn({
  projectData,
  fullWeek,
  canApproveForms,
}: {
  projectData: UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInner;
  fullWeek: Array<Date> | null;
  canApproveForms: boolean;
}) {
  const { formatHours } = useLocale();
  const { viewDates } = usePlanning();
  const { isHoliday } = useHolidays(viewDates[0]);

  const hasUnapprovedForm = useMemo(() => {
    return projectData.forms.some((form) => form.approved === null);
  }, [projectData.forms]);

  return (
    <Fragment>
      <tr key={projectData.id} className="bg-shade-50 font-semibold">
        <td className="align-top">
          <OptionalLink to={linkToProject(projectData.id)} openInNewTab={true}>
            {projectData.name}
          </OptionalLink>
          {canApproveForms && (
            <FormApprovalSection
              forms={projectData.forms}
              projectId={projectData.id}
              defaultChecked={!hasUnapprovedForm}
            />
          )}
        </td>
        {fullWeek?.map((day) => (
          // Create column for each day of the week
          <td
            key={`${projectData.id}-${day.getDate()} - ${day.getDay()}`}
            className={twMerge(
              "align-top",
              isHoliday(day) ? "bg-yellow-50" : isWeekend(day) ? "bg-gray-200" : "bg-white-primary"
            )}
          >
            <div className="flex flex-col gap-2">
              {projectData.forms.map(
                (
                  form: UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner
                ) => {
                  const theDay = day.getDay();
                  // Search trough user entries to match day of the week
                  const dayOfWeek = new Date(form?.date as Date).getDay();
                  const totalFormHours = form.entries.reduce(
                    (
                      sum: number,
                      entry: UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner
                    ) => sum + entry.totalHours,
                    0
                  );
                  return (
                    theDay === dayOfWeek && (
                      <Fragment key={`${form?.id} - ${projectData.id}`}>
                        <div className="row">
                          <OptionalLink to={linkToFormNew(form.id)} openInNewTab={true}>
                            <Tooltip
                              trigger={
                                <span>
                                  {form.label.length ? form.label : formatHours(totalFormHours)}
                                </span>
                              }
                              className="text-sm"
                            >
                              {form.entries.map(
                                (
                                  entry: UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner
                                ) => (
                                  <div key={entry.id}>
                                    {`${formatHours(entry.totalHours)} - ${entry.entryType}`}
                                  </div>
                                )
                              )}
                            </Tooltip>
                          </OptionalLink>
                          {canApproveForms && (
                            <FormApprovalSection
                              forms={[form]}
                              projectId={projectData.id}
                              defaultChecked={!!form.approved}
                            />
                          )}
                        </div>
                      </Fragment>
                    )
                  );
                }
              )}
            </div>
          </td>
        ))}
      </tr>
    </Fragment>
  );
}

const FormApprovalSection = ({
  forms,
  defaultChecked,
  projectId,
}: {
  forms: Array<UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner>;
  defaultChecked?: boolean;
  projectId: string;
}) => {
  const api = useAPI();
  const queryClient = useQueryClient();

  const mutateApproval = useMutation({
    mutationKey: ["employees.weekly", "changeStatus"],
    mutationFn: async (data: ChangeStatusOperationRequest) => api.changeStatus(data),
    onSuccess: () => null, // skip toast success message
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["employees.weekly"],
      });
    },
  });

  async function handleApproveForm(formIds: Array<string>, newStatus: boolean) {
    await mutateApproval.mutateAsync({
      projectId: projectId,
      changeStatusRequest: {
        approve: newStatus,
        forms: formIds,
      },
    });
  }

  return (
    <>
      {mutateApproval.isPending ? (
        <Spinner className="ml-2 mr-3 h-5 w-5" />
      ) : (
        <Checkbox
          className="ml-2 inline"
          checked={defaultChecked}
          onChange={(e) =>
            handleApproveForm(
              forms.map((f) => f.id),
              e.currentTarget.checked
            )
          }
        />
      )}
    </>
  );
};
