import { useOutletContext } from "react-router-dom";
import { IEditProductBundleRequest, ProductBundle } from "@apacta/sdk";
import { Suspense, useEffect, useRef, useState } from "react";
import { PageSpinner } from "~/lib/ui/page-spinner";
import { useTranslation } from "react-i18next";
import { useFormState } from "~/lib/form-state";
import { useAPI } from "~/lib/api";
import { useToasts } from "~/lib/toast/use-toasts";
import { z } from "zod";
import { ContributionMarginCard, ContributionRatioCard } from "~/pages/projects/_kpi-cards";
import { OrderLinesBuilder } from "~/lib/ui/order-lines/order-lines-builder";
import { OrderLinesBuilderRef, OrderLinesColumns } from "~/lib/ui/order-lines/lib/types";
import OrderLinesBuilderProvider from "~/lib/ui/order-lines/order-lines-builder-context";
import TextInput from "~/lib/ui/form-elements/text-input";
import Textarea from "~/lib/ui/form-elements/textarea";
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import ImageUpload, { PreviewFile } from "~/lib/ui/form-elements/image-upload";
import {
  orderLinesToProductBundleLinesTransformer,
  productBundleLineToOrderLineTransformer,
} from "~/lib/ui/order-lines/lib/transformers/product-bundle-transformers";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { useMe } from "~/lib/auth/use-me";
import { Button } from "~/lib/ui";
import { KPIBetaCard } from "~/pages/projects/_cmp/kpi-beta-card";
import { PageLayout } from "~/lib/ui/page-layout";
import { useNavigate } from "react-router";
import { NumberInput } from "~/lib/ui/form-elements";

const maxContributionRatio = 99.999999;

export default function EditProductBundlePage() {
  const me = useMe();
  const { t } = useTranslation();
  const { productBundle } = useOutletContext<{ productBundle: ProductBundle }>();
  const api = useAPI();
  const orderLinesBuilderRef = useRef<OrderLinesBuilderRef>(null);
  const [linesModified, setLinesModified] = useState<boolean>(false);
  const [totalCostPrice, setTotalCostPrice] = useState<number>(0);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const kpiQuery = useSuspenseQuery({
    queryKey: ["product_bundle_kpi", productBundle.id],
    queryFn: () =>
      api.productBundlesGetKpiCardsData({
        productBundleId: productBundle.id as string,
      }),
  });
  /** Calculating total sales price */
  const handleChangeContributionRatio = (v: number) => {
    let contributionRatioOverwrite = v;
    if (v > maxContributionRatio) {
      contributionRatioOverwrite = maxContributionRatio;
    }

    setValues({
      total_sales_price:
        totalCostPrice > 0
          ? Math.round(totalCostPrice / (1 - contributionRatioOverwrite / 100))
          : 0,
      contribution_ratio: v,
    });
  };

  const handleChangeSalesPrice = (v: number) => {
    setValues({
      total_sales_price: v,
      contribution_ratio: Math.round(kpiFormData.getValue("contributionMargin")),
    });
  };

  const orderLinesActionVisibility = {
    showAddProduct: true,
    showAddHours: true,
    showAddProductBundle: false,
    showAddText: false,
    showTotal: true,
    showVAT: false,
    showSubtotal: false,
    sumCostPrice: true,
  };
  const orderLinesColumnOptions: OrderLinesColumns = {
    handle: { visible: true },
    product: { visible: true },
    quantity: { visible: true },
    costPrice: { visible: true },
    salePrice: { visible: false },
    discount: { visible: false },
    total: { visible: true },
    actions: { visible: true },
  };
  const kpiData = kpiQuery.data.data;
  const kpiFormData = useFormState({
    schema: {
      hoursCostPrice: z.number(),
      productsCostPrice: z.number(),
      totalCostPrice: z.number(),
      hoursSalesPrice: z.number(),
      productsSalesPrice: z.number(),
      totalSalesPrice: z.number(),
      contributionMargin: z.number(),
      workingHoursMargin: z.number(),
      profitMargin: z.number(),
    },
    initialValues: {
      hoursCostPrice: kpiData.hoursCostPrice ?? 0,
      productsCostPrice: kpiData.productsCostPrice ?? 0,
      totalCostPrice: kpiData.totalCostPrice ?? 0,
      hoursSalesPrice: kpiData.hoursSalesPrice ?? 0,
      productsSalesPrice: kpiData.productsSalesPrice ?? 0,
      totalSalesPrice: kpiData.totalSalesPrice ?? 0,
      contributionMargin: kpiData.contributionMargin ?? 0,
      workingHoursMargin: kpiData.workingHoursMargin ?? 0,
      profitMargin: kpiData.profitMargin ?? 0,
    },
  });

  const { isValid, isModified, getValue, registerNumberInput, register, setValues } = useFormState({
    schema: {
      name: z.string(),
      description: z.string().optional(),
      file_id: z.string().optional(),
      total_sales_price: z.number().optional(),
      contribution_ratio: z.number().optional(),
    },
    initialValues: {
      name: productBundle.name,
      description: productBundle.description ?? undefined,
      file_id: productBundle.file?.id,
      total_sales_price: productBundle.salesPrice || 0.0,
      contribution_ratio: 0.0,
    },
  });

  const toast = useToasts();

  const productBundleEdit = useMutation({
    mutationFn: (args: IEditProductBundleRequest) => api.iEditProductBundle(args),
    onSuccess: () => {
      toast.showTemplate("CHANGES_SAVED");
    },
  });

  const handleSaveProductBundle = () => {
    const lines = orderLinesBuilderRef.current?.getLines();
    const productBundleLines = orderLinesToProductBundleLinesTransformer(lines ?? [], {
      productBundleId: productBundle?.id,
    });
    orderLinesBuilderRef.current?.resetInitialValues();

    productBundleEdit.mutate(
      {
        productBundleId: productBundle.id,
        iCreateProductBundleRequest: {
          name: getValue("name"),
          description: getValue("description"),
          salesPrice: getValue("total_sales_price"),
          fileId: productBundle.file?.id ?? null,
          productBundleLines: productBundleLines,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["product_bundle", productBundle.id] });
        },
      }
    );
  };

  const initialFile: PreviewFile | undefined = productBundle.file
    ? {
        url: productBundle.file.fileUrl,
        type: productBundle.file.mimeType as string,
        name: productBundle.file.originalFilename,
      }
    : undefined;

  const fileRemove = async () => {
    await api.iEditProductBundle({
      productBundleId: productBundle.id,
      iCreateProductBundleRequest: {
        name: productBundle.name,
        fileId: null,
      },
    });

    await queryClient.invalidateQueries({ queryKey: ["product_bundle", productBundle.id] });
  };

  const fileUpload = (file: File) =>
    api.iProductBundleUploadFile({
      productBundleId: productBundle.id as string,
      file: file,
    });

  const registerModified = () => {
    if (orderLinesBuilderRef.current) {
      setLinesModified(orderLinesBuilderRef.current.isModified());
      let lineTotalHoursCostPrice: number = 0;
      let lineTotalHoursSalesPrice: number = 0;
      let lineTotalProductCostPrice: number = 0;
      let lineTotalProductSalesPrice: number = 0.0;
      let totalHours: number = 0.0;
      orderLinesBuilderRef.current?.getLines().forEach((line) => {
        if (!line.usersPriceGroupId && line.costPrice && line.sellingPrice && line.quantity) {
          lineTotalProductCostPrice += line.costPrice * line.quantity;
          lineTotalProductSalesPrice += line.sellingPrice * line.quantity;
        }
        if (line.usersPriceGroupId && line.costPrice && line.sellingPrice && line.quantity) {
          lineTotalHoursCostPrice += line.costPrice * line.quantity;
          lineTotalHoursSalesPrice += line.sellingPrice * line.quantity;
          totalHours += line.quantity;
        }
      });
      setTotalCostPrice(getTotalCostPrice());
      const totalSalesPrice = getValue("total_sales_price") ?? getTotalSalesPrice();
      const contributionMargin = totalSalesPrice - totalCostPrice;

      kpiFormData.setValues({
        hoursCostPrice: lineTotalHoursCostPrice,
        productsCostPrice: lineTotalProductCostPrice,
        totalCostPrice: totalCostPrice,
        hoursSalesPrice: lineTotalHoursSalesPrice,
        productsSalesPrice: lineTotalProductSalesPrice,
        totalSalesPrice: totalSalesPrice,
        contributionMargin: contributionMargin,
        workingHoursMargin: contributionMargin > 0 ? contributionMargin / totalHours : 0,
        profitMargin: contributionMargin > 0 ? contributionMargin / totalSalesPrice : 0,
      });
      setValues({
        total_sales_price: totalSalesPrice,
      });
    }
  };

  useEffect(() => {
    registerModified();
    const totalSalesPrice = getValue("total_sales_price") ?? getTotalSalesPrice();

    const contributionMargin =
      totalSalesPrice > 0 && totalSalesPrice > totalCostPrice
        ? totalSalesPrice - totalCostPrice
        : 0;

    kpiFormData.setValues({
      totalSalesPrice: totalSalesPrice,
      profitMargin: totalSalesPrice > 0 ? contributionMargin / totalSalesPrice : 0,
    });

    setValues({
      total_sales_price: totalSalesPrice,
      contribution_ratio:
        totalSalesPrice > 0
          ? Math.round((contributionMargin / totalSalesPrice) * 100)
          : getValue("contribution_ratio"),
    });

    return () => undefined;
  }, [getValue("total_sales_price"), getValue("contribution_ratio"), totalCostPrice]);

  const getTotalCostPrice = () => {
    let totalLineCostPrice = 0;
    if (orderLinesBuilderRef.current) {
      let lineTotalHoursCostPrice: number = 0;
      let lineTotalProductCostPrice: number = 0;
      orderLinesBuilderRef.current?.getLines().forEach((line) => {
        // Working hours
        if (!line.usersPriceGroupId && line.costPrice && line.quantity) {
          lineTotalProductCostPrice += line.costPrice * line.quantity;
        }
        // Product
        if (line.usersPriceGroupId && line.costPrice && line.quantity) {
          lineTotalHoursCostPrice += line.costPrice * line.quantity;
        }
      });
      totalLineCostPrice = lineTotalHoursCostPrice + lineTotalProductCostPrice;
    }
    return totalLineCostPrice;
  };

  const getTotalSalesPrice = () => {
    let totalSalesPrice = 0;
    if (orderLinesBuilderRef.current) {
      let lineTotalHoursSalesPrice: number = 0;
      let lineTotalProductSalesPrice: number = 0.0;
      orderLinesBuilderRef.current?.getLines().forEach((line) => {
        if (!line.usersPriceGroupId && line.sellingPrice && line.quantity) {
          lineTotalProductSalesPrice += line.sellingPrice * line.quantity;
        }
        if (line.usersPriceGroupId && line.sellingPrice && line.quantity) {
          lineTotalHoursSalesPrice += line.sellingPrice * line.quantity;
        }
      });

      totalSalesPrice = lineTotalHoursSalesPrice + lineTotalProductSalesPrice;
    }

    return totalSalesPrice;
  };

  return (
    <PageLayout
      title={productBundle ? productBundle.name : t("common:product_bundle")}
      onBackClick={() => navigate(-1)}
      renderActions={() => (
        <Button
          onClick={() => handleSaveProductBundle()}
          variant="tertiary"
          disabled={(!isModified && !linesModified) || !isValid}
        >
          {t("common:save_changes")}
        </Button>
      )}
    >
      <div className="flex w-full flex-col gap-12">
        <Suspense
          fallback={
            <div className="relative h-96 w-full">
              <PageSpinner loadingMessage={t("common:loading")} />
            </div>
          }
        >
          <>
            <div className="flex  flex-col gap-8">
              <div className="flex flex-col gap-8 md:flex-row">
                <div className="flex-1">
                  <div className="flex flex-col gap-6 sm:rounded-lg">
                    <div className="flex gap-4">
                      <KPIBetaCard
                        label={t("finance:cost_price")}
                        current={{
                          label: t("common:work_hour_usage"),
                          value: kpiFormData.getValue("hoursCostPrice"),
                          className: "bg-gray-300 text-black",
                        }}
                        estimated={{
                          label: t("finance:cost_price"),
                          value: kpiFormData.getValue("totalCostPrice"),
                        }}
                        remainder={{
                          label: t("common:product_usage"),
                          className: "bg-green-600 text-white",
                        }}
                        unit="currency"
                        Icon={CurrencyDollarIcon}
                        barClasses={{ primary: "bg-gray-300", remainder: "bg-green-600" }}
                        containerClass="w-72"
                      />
                      <KPIBetaCard
                        label={t("finance:total")}
                        current={{
                          label: t("finance:cost_price"),
                          value: kpiFormData.getValue("totalCostPrice"),
                          className: "bg-gray-300 text-black",
                        }}
                        estimated={{
                          label: t("finance:sales_price"),
                          value: kpiFormData.getValue("totalSalesPrice"),
                        }}
                        remainder={{
                          label: t("finance:contribution"),
                          className: "bg-green-600 text-white",
                        }}
                        unit="currency"
                        Icon={CurrencyDollarIcon}
                        barClasses={{ primary: "bg-gray-300", remainder: "bg-green-600" }}
                        containerClass="w-72"
                      />

                      <ContributionMarginCard
                        label={t("finance:contribution_margin_per_hour")}
                        paddingXClassname=""
                        contributionMargin={kpiFormData.getValue("workingHoursMargin")}
                      />
                      <ContributionRatioCard
                        label={t("common:contribution_ratio")}
                        paddingXClassname=""
                        contributionRatio={kpiFormData.getValue("profitMargin")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-8 md:flex-row">
                <div className="flex-1">
                  <h3 className="mb-5">{t("common:sales_price")}</h3>
                  <div className="flex flex-col gap-6 bg-white p-4 shadow  sm:rounded-lg">
                    <div className="flex justify-end gap-8">
                      <div className="w-5/12">
                        <NumberInput
                          {...registerNumberInput("contribution_ratio")}
                          disabled={totalCostPrice <= 0}
                          label={t("common:contribution_ratio")}
                          onChangeValue={(v) => handleChangeContributionRatio(v)}
                          className="text-right"
                        />
                      </div>
                      <div className="w-2/12 pt-8 text-center">{t("common:or", "eller")}</div>
                      <div className="w-5/12">
                        <NumberInput
                          {...registerNumberInput("total_sales_price")}
                          disabled={totalCostPrice <= 0}
                          label={t("finance:sales_price")}
                          onChangeValue={(v) => handleChangeSalesPrice(v)}
                          className="text-right"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-8 md:flex-row">
                <div className="flex-1">
                  <h2 className="mb-5">{t("common:description")}</h2>
                  <div className="flex bg-white p-6 shadow sm:gap-8 sm:rounded-lg md:flex-row">
                    <div className="flex h-auto flex-col gap-6 md:basis-1/2">
                      <TextInput {...register("name")} label={t("common:name")} />
                      <div className="flex h-32 basis-3/5 flex-col gap-8">
                        <Textarea
                          initialValue={productBundle.description}
                          autoHeight={false}
                          fullHeight={true}
                          {...register("description")}
                          label={t("common:description")}
                        />
                      </div>
                    </div>

                    <div className="flex flex-1 flex-col justify-start gap-6 md:basis-1/2">
                      {/* Here we should insert labels when implemented */}
                      <ImageUpload
                        initialFile={initialFile}
                        uploadFn={(file: File) => fileUpload(file)}
                        removeFn={fileRemove}
                        label={t("common:image", "Image")}
                      ></ImageUpload>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex h-full flex-col gap-8">
              <div className="flex md:flex-row">
                <div className="flex-1">
                  <h2 className="mb-5">{t("common:content")}</h2>
                  <div className="flex bg-white p-6 shadow sm:gap-8 sm:rounded-lg md:flex-row">
                    <OrderLinesBuilderProvider
                      cacheKey={["pb", productBundle.id]}
                      dataFn={() =>
                        api.productBundleLinesList({ productBundleId: productBundle.id })
                      }
                      transformerFn={productBundleLineToOrderLineTransformer}
                      onChange={() => registerModified()}
                      initialVatPercent={me.company.vatPercent}
                    >
                      <OrderLinesBuilder
                        ref={orderLinesBuilderRef}
                        editMode={true}
                        companyVatPercent={me.company.vatPercent}
                        options={{
                          orderLinesActionVisibility: orderLinesActionVisibility,
                          columns: orderLinesColumnOptions,
                        }}
                      />
                    </OrderLinesBuilderProvider>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Suspense>
      </div>
    </PageLayout>
  );
}
