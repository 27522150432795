import { NavItem } from "../navigation-item/navigation-item.type";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useFeatureFlags } from "~/lib/feature-flags";
import { useRoles } from "~/lib/auth/use-roles";
import { useSession, FeaturesType } from "~/lib/auth/session";
import Badge from "~/lib/ui/badge";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { debounce } from "lodash";
import { useNavigate } from "react-router";
import Collapsible from "~/lib/ui/collapsible";
import { Icon } from "~/lib/ui";
import { iframeNavigationStateHack } from "~/lib/utils/iframe";

export function NavigationItem({
  item,
  isSubItem = false,
  isCollapsed,
  onClick,
}: {
  item: NavItem;
  isSubItem?: boolean;
  isCollapsed?: boolean;
  onClick?: () => void;
}) {
  const isActive = false; // TODO: Revisit when we are rid of frames
  const [isOpen, setIsOpen] = useState<boolean>(false); // only used for Collapsible
  const [isOver, setIsOver] = useState<boolean>(false); // only used for Popover

  const { me } = useSession();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const features = useFeatureFlags();
  const roles = useRoles();

  /** Use debounce from Lodash to ensure we don't get a lot of popovers when moving the cursor really fast */
  const debouncedMouseEnterFn = debounce((type?: "open" | undefined) => {
    if (type === "open") {
      setIsOpen(true);
    } else {
      setIsOver(true);
    }
  }, 300);

  const handleMouseLeave = () => {
    setIsOver(false);
    debouncedMouseEnterFn.cancel();
  };

  // Skip any items that are locked behind a feature flag
  if (item.featureName && !features.has(item.featureName as FeaturesType)) {
    return null;
  }

  // Skip any items that are locked behind a role
  if (item.role && !roles.has(item.role)) {
    return null;
  }

  // Skip any items that should be hidden
  if (me && item.isHidden?.(me)) {
    return null;
  }

  // Return Disclosure if item has children
  if (item.children) {
    return (
      <Collapsible
        asChild
        triggerRender={(open) => (
          <div className="my-1">
            <button
              className={twMerge(
                isActive
                  ? "rounded-lg bg-hover text-white hover:rounded-lg"
                  : "text-gray-200 hover:bg-hover",
                isSubItem ? "pl-11 pr-2" : "pl-2",
                "group relative flex w-full items-center rounded-lg py-2 text-base"
              )}
            >
              {item.icon ? (
                <item.icon
                  className={twMerge("mr-3 h-6 w-6 flex-shrink-0 text-gray-200 hover:bg-hover")}
                  aria-hidden="true"
                />
              ) : null}
              <div className={twMerge("whitespace-nowra flex w-full items-center")}>
                <div className="flex w-full items-center justify-between">
                  <span>{item.name}</span>
                  {open ? (
                    <Icon
                      name="chevronUp"
                      className="mr-2 h-5 w-5 flex-shrink-0 transform text-gray-200 transition-colors duration-150 ease-in-out group-hover:text-white"
                    />
                  ) : (
                    <Icon
                      name="chevronDown"
                      className="mr-2 h-5 w-5 flex-shrink-0 transform text-gray-200 transition-colors duration-150 ease-in-out group-hover:text-white"
                    />
                  )}
                </div>
              </div>
            </button>
          </div>
        )}
      >
        {item.children?.map((subItem, idx) => (
          <NavigationItem item={subItem} key={idx} isSubItem={true} onClick={onClick} />
        ))}
      </Collapsible>
    );
  }

  // Return anchor tag with link if item is targeting external website
  if (item.external) {
    return (
      <div className="my-1">
        <div>
          <a
            href={item.href}
            target="_blank"
            rel="noreferrer"
            className={twMerge(
              isSubItem ? "pl-11 pr-2" : "pl-2",
              "group relative flex w-full items-center justify-start rounded-lg py-2 text-base text-gray-200 hover:bg-hover"
            )}
          >
            {item.icon ? (
              <item.icon
                className={twMerge("mr-3 h-6 w-6 flex-shrink-0 text-gray-200 hover:bg-hover")}
                aria-hidden="true"
              />
            ) : null}
            <div className={twMerge("flex whitespace-nowrap", "")}>
              <div className="flex">{item.name}</div>
              {item.badge ? (
                <Badge
                  className={twMerge("ml-4", item.badge.className)}
                  size="sm"
                  label={item.badge.label}
                />
              ) : null}
            </div>
          </a>
        </div>
      </div>
    );
  }

  // We must not post empty links when our frames are dependent on location, it re-renders everything
  if (onClick) {
    return (
      <div className="my-1">
        <div>
          <button
            onClick={() => onClick && onClick()}
            className={twMerge(
              isActive ? "rounded-lg bg-hover text-white" : "text-gray-200 hover:bg-hover",
              isSubItem ? "pl-11 pr-2" : "pl-2",
              "group relative flex w-full rounded-lg py-2 text-base"
            )}
          >
            {item.icon ? (
              <item.icon
                className="mr-3 h-6 w-6 flex-shrink-0 text-gray-200 hover:bg-hover"
                aria-hidden="true"
              />
            ) : null}
            <div className="flex whitespace-nowrap rounded-lg">
              <div className="flex">{item.name}</div>
            </div>
          </button>
        </div>
      </div>
    );
  }

  // Return NavLink if no other conditions are met
  return (
    <div className="my-1">
      <NavLink
        to={item.href}
        // This is a hack to trick the iFrame into re-render, remove when iFrames are gone
        state={iframeNavigationStateHack()}
      >
        {(link) => (
          <div>
            <div
              className={twMerge(
                link.isActive ? "rounded-lg bg-hover text-white" : "text-gray-200 hover:bg-hover",
                isSubItem ? "pl-11 pr-2" : "pl-2",
                "group relative flex w-full items-center rounded-lg py-2 pr-0 text-base"
              )}
            >
              {item.icon ? (
                <item.icon
                  className={twMerge("mr-3 h-6 w-6 flex-shrink-0 text-gray-200 hover:bg-hover")}
                  aria-hidden="true"
                />
              ) : null}
              <div
                className={twMerge("static flex whitespace-nowrap rounded-lg group-hover:w-fit")}
              >
                <div className="flex group-hover:pr-4">
                  {item.name}
                  {item.badge ? (
                    <Badge
                      className={twMerge("ml-4", item.badge.className)}
                      size="sm"
                      label={item.badge.label}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </NavLink>
    </div>
  );
}
