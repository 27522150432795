import { Duration, formatISODuration } from "date-fns";
import { Label } from "../label";
import { Select } from "./select";
import { range } from "~/lib/utils/utils";
import { useTranslation } from "react-i18next";

export function DurationInput({
  ...props
}: {
  value?: Duration;
  onChange?: (duration: Duration, key?: string) => void;
  label: string;
  required?: boolean;
  onChangeString?: (duration: string, key?: string) => void;
  minuteOptions?: Array<number>;
  hoursOptions?: Array<number>;
}) {
  const { t } = useTranslation();
  function handleChange(key: "hours" | "minutes", value: string) {
    const dur = { ...props.value };
    dur[key] = parseInt(value, 10);
    props.onChange?.(dur, key);
    props.onChangeString?.(formatISODuration(dur), key);
  }

  const { hours, minutes } = props.value ?? {};
  const minuteOptions = props.minuteOptions ?? range(60).map((i) => i);
  const hoursOptions = props.hoursOptions ?? range(24).map((i) => i);
  return (
    <>
      {props.label && <Label required={props.required}>{props.label}</Label>}
      <div className="flex flex-row items-center gap-2">
        <Select onChange={(e) => handleChange("hours", e.currentTarget.value)} value={hours}>
          {hoursOptions.map((i) => (
            <option value={i} key={i}>
              {`${i} ${t("common:hour", { count: i }).toLocaleLowerCase()}`}
            </option>
          ))}
        </Select>
        <Select onChange={(e) => handleChange("minutes", e.currentTarget.value)} value={minutes}>
          {minuteOptions.map((i) => (
            <option value={i} key={i}>
              {`${i} ${t("common:minute", { count: i }).toLocaleLowerCase()}`}
            </option>
          ))}
        </Select>
      </div>
    </>
  );
}
